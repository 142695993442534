import React, { HTMLAttributes, useContext } from 'react';
import { ButtonProps } from '@mui/material/Button';
import type { CustomTypographyProps } from 'components/Typography/types';
import { PostContext } from '../PostContentParts';
import MetricsUI from './MetricsUI';
interface Metrics {
  views?: number | null;
  forwards?: number | null;
  replies?: number | null;
  reactions?: number | null;
}
interface MetricsProps extends HTMLAttributes<HTMLDivElement>, Metrics {
  buttonProps?: ButtonProps;
  typographyProps?: CustomTypographyProps;
  scroll?: boolean;
  classes?: {
    root?: string;
    button?: string;
    icon?: string;
  };
}
const Metrics: React.FC<MetricsProps> = ({
  className,
  scroll,
  ...rest
}) => {
  const post = useContext(PostContext);
  if (!post) return null;
  return <MetricsUI channelId={post.channelId} postId={post.id} className={className} views={post.views} reactions={post.reactions} replies={post.replies} forwards={post.forwards} scroll={scroll} isAd={post.isAds} {...rest} data-sentry-element="MetricsUI" data-sentry-component="Metrics" data-sentry-source-file="Metrics.tsx" />;
};
export default Metrics;