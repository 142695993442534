import React from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styles from './PopperTrigger.module.scss';
interface PopperTriggerProps extends Omit<PopperProps, 'open' | 'onClose'> {
  closeFnAsPropName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...params: any) => void;
  children: React.ReactNode;
  popperContent: React.ReactElement;
}
const PopperTrigger: React.FC<PopperTriggerProps> = ({
  children,
  popperContent,
  closeFnAsPropName = 'close',
  onClose,
  ...popperProps
}) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
    setOpen(prev => !prev);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: MouseEvent | TouchEvent, ...additionalParams: any[]) => {
    event.stopPropagation();
    if (open) {
      setOpen(false);
      if (onClose) {
        onClose(...additionalParams);
      }
    }
  };
  return <ClickAwayListener onClickAway={handleClose} data-sentry-element="ClickAwayListener" data-sentry-component="PopperTrigger" data-sentry-source-file="PopperTrigger.tsx">
      <div>
        {React.cloneElement(children as React.ReactElement, {
        onClick: handleClick
      })}
        <Popper anchorEl={anchorEl} open={open} placement="bottom-start" className={styles.popper} {...popperProps} data-sentry-element="Popper" data-sentry-source-file="PopperTrigger.tsx">
          {React.cloneElement(popperContent, {
          [closeFnAsPropName]: handleClose
        })}
        </Popper>
      </div>
    </ClickAwayListener>;
};
export default PopperTrigger;