'use client';

import React from 'react';
import cx from 'classnames';
import TooltipMUI, { TooltipProps } from '@mui/material/Tooltip';
import { ReactComponent as ArrowIcon } from 'public/assets/icons/tooltip-arrow.svg';
import styles from './Tooltip.module.scss';
const Tooltip: React.FC<TooltipProps> = ({
  classes,
  children,
  ...rest
}) => {
  return <TooltipMUI classes={{
    ...classes,
    tooltip: cx(styles.tooltip, classes?.tooltip),
    popper: cx(styles.popper, classes?.popper),
    arrow: cx(styles.arrow, classes?.arrow)
  }} {...rest} components={{
    Arrow: ArrowIcon
  }} data-sentry-element="TooltipMUI" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      {children}
    </TooltipMUI>;
};
export default Tooltip;