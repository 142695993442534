import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import Typography from 'components/Typography';
import type { CustomTypographyProps } from 'components/Typography/types.d';
import styles from './Tag.module.scss';
interface TagProps extends HTMLAttributes<HTMLDivElement> {
  typographyProps?: CustomTypographyProps;
}
const Tag: React.FC<TagProps> = ({
  className,
  children,
  typographyProps = {}
}) => {
  return <div className={cx(styles.root, className)} data-sentry-component="Tag" data-sentry-source-file="Tag.tsx">
      <Typography variant="label12" color="secondary" component="span" {...typographyProps} data-sentry-element="Typography" data-sentry-source-file="Tag.tsx">
        {children}
      </Typography>
    </div>;
};
export default Tag;