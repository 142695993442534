import React, { HTMLAttributes, useContext } from 'react';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { PostType } from 'business/channel/repository/types.d';
import { CustomTypographyProps } from 'components/Typography/types.d';
import ImageWithErrorState from 'components/ImageWithErrorState';
import Typography from 'components/Typography';
import getStaticStormUrl from 'utils/staticStorm';
import { ReactComponent as InfoIcon } from 'public/assets/icons/info.svg';
import { PostContext } from '../PostContentParts';
import styles from './Gallery.module.scss';
interface GalleryProps extends HTMLAttributes<HTMLDivElement> {
  previewCount: number;
  classes?: {
    root?: string;
    container?: string;
    media?: string;
  };
  moreMediaProps?: {
    className?: string;
    typographyVariant?: CustomTypographyProps['variant'];
  };
}
const MediaGalleryTypes = [PostType.Video, PostType.Photo] as string[];
const Gallery: React.FC<GalleryProps> = ({
  previewCount,
  className,
  classes,
  moreMediaProps,
  children
}) => {
  const {
    media = []
  } = useContext(PostContext) || {};
  const t = useTranslations('post.media');
  const galleryMedia = media.filter(({
    mediaType
  }) => MediaGalleryTypes.includes(mediaType));
  const mediaToShow = galleryMedia.slice(0, previewCount);
  const moreMediaCount = galleryMedia.length - mediaToShow.length;
  if (!mediaToShow.length) return null;
  return <div className={cx(styles.root, classes?.root)} data-sentry-component="Gallery" data-sentry-source-file="Gallery.tsx">
      <div data-gallery-count={mediaToShow.length} className={cx(styles.container, classes?.container, className)}>
        {mediaToShow.map((media, index) => {
        const mediaUrl = media.thumbnailUrl || media.url;
        const isVideo = media.mediaType === PostType.Video;
        const isLastMedia = index === mediaToShow.length - 1;
        if (!mediaUrl) return <div key={media.uniqueId} className={cx(styles.media, classes?.media)}>
                <div className={styles.error}>
                  <InfoIcon />
                  {index === 0 && <Typography variant="h12">{ReactHtmlParser(t('noAccess'))}</Typography>}
                </div>
              </div>;
        return <div key={media.uniqueId} className={cx(styles.media, classes?.media)}>
              <ImageWithErrorState showErrorMessage={false} className={styles.imageBG} src={`${getStaticStormUrl(mediaUrl)}?w=400&b=15`} alt="Post media background" width={400} height={400} />
              <ImageWithErrorState isVideo={isVideo} showErrorMessage={index === 0} moreMediaCount={isLastMedia ? moreMediaCount : 0} moreMediaProps={moreMediaProps} className={styles.image} src={`${getStaticStormUrl(mediaUrl)}?w=400`} alt="Post media" width={400} height={400} />
            </div>;
      })}
      </div>
      {children}
    </div>;
};
export default Gallery;