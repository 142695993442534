import { Reducer } from 'react';

const INITIAL_STATE = {
  playing: false,
  progress: {
    playedSeconds: 0,
  },
  duration: 0,
};

type ReducerActions = {
  type: 'PLAY' | 'PAUSE' | 'TOGGLE_PLAY' | 'DURATION' | 'PROGRESS' | 'RESET';
  payload?: unknown;
};

// @ts-ignore
const reducer: Reducer<typeof INITIAL_STATE, ReducerActions> = (state, action) => {
  switch (action.type) {
    case 'PLAY':
      return { ...state, playing: true };
    case 'PAUSE':
      return { ...state, playing: false };
    case 'TOGGLE_PLAY':
      return { ...state, playing: !state.playing };
    case 'DURATION':
      return { ...state, duration: action.payload };
    case 'PROGRESS':
      return { ...state, progress: { playedSeconds: action.payload } };
    case 'RESET':
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

export { reducer, INITIAL_STATE };
