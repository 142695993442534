import React, { HTMLAttributes } from 'react';
// @ts-ignore
import shortNumber from 'short-number';
import { ButtonProps } from '@mui/material/Button';
import cx from 'classnames';
import { useRouter } from 'utils/navigation';
import Typography from 'components/Typography';
import Button from 'components/Button';
import type { CustomTypographyProps } from 'components/Typography/types';
import { ReactComponent as EyeIcon } from 'public/assets/icons/eye.svg';
// import { ReactComponent as Share2Icon } from 'public/assets/icons/share2.svg';
import { ReactComponent as ThumbIcon } from 'public/assets/icons/thumb.svg';
import { ReactComponent as ShareIcon } from 'public/assets/icons/share.svg';
import { ReactComponent as AdIcon } from 'public/assets/icons/ad.svg';
import styles from './MetricsUI.module.scss';
interface MetricsUIProps extends HTMLAttributes<HTMLDivElement> {
  isAd?: boolean;
  views?: number | null;
  forwards?: number | null;
  replies?: number | null;
  reactions?: number | null;
  channelId: string;
  postId: string;
  buttonProps?: ButtonProps;
  typographyProps?: CustomTypographyProps;
  scroll?: boolean;
  classes?: {
    root?: string;
    button?: string;
    icon?: string;
  };
}
const metrics = {
  views: {
    Icon: EyeIcon
  },
  forwards: {
    Icon: ShareIcon
  },
  // replies: {
  //   Icon: ShareIcon,
  // },
  reactions: {
    Icon: ThumbIcon
  }
};
const MetricsUI: React.FC<MetricsUIProps> = ({
  className,
  views,
  forwards,
  replies,
  reactions,
  channelId,
  postId,
  buttonProps,
  typographyProps,
  classes = {},
  scroll = false,
  isAd = false,
  ...rest
}) => {
  const router = useRouter();
  const metricsData = {
    views,
    forwards,
    replies,
    reactions
  };
  const handleClickMetric = (e: MouseEvent) => {
    e.stopPropagation();
    router.push({
      pathname: '/channel/[id]/[postId]/stats/views',
      params: {
        id: channelId,
        postId
      }
    }, {
      scroll
    });
  };
  return <div className={styles.root} data-sentry-component="MetricsUI" data-sentry-source-file="MetricsUI.tsx">
      <div className={cx(classes?.root, className)} {...rest}>
        {/* @ts-ignore */}
        {Object.keys(metrics).map((metricKey: keyof typeof metrics) => {
        const {
          Icon
        } = metrics[metricKey];
        return <Button key={metricKey} variant="text" size="small" className={cx(styles.metric, classes?.button)} {...buttonProps} onClick={handleClickMetric}>
              <Icon className={cx(styles.metricIcon, classes?.icon)} />
              <Typography component="span" variant="h12bk" color="secondary" {...typographyProps}>
                {shortNumber(metricsData[metricKey] || 0)}
              </Typography>
            </Button>;
      })}
      </div>
      {isAd && <AdIcon className={styles.adIcon} />}
    </div>;
};
export default MetricsUI;