import React from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from 'components/Typography';
import { ReactComponent as SuccessIcon } from 'public/assets/icons/snackbar-success.svg';
import { ReactComponent as ErrorIcon } from 'public/assets/icons/snackbar-error.svg';
import { ReactComponent as CloseIcon } from 'public/assets/icons/close.svg';
import styles from './Snackbar.module.scss';
export enum SnackbarType {
  success = 'success',
  error = 'error',
  warn = 'warning',
  info = 'info',
}
export type SnackbarProps = {
  title?: React.ReactNode | null;
  message: React.ReactNode;
  type: SnackbarType;
  close(): void;
  autoHideDuration?: number | null;
};
const Snackbar: React.FC<SnackbarProps> = ({
  title,
  message,
  autoHideDuration,
  type,
  close
}) => <MuiSnackbar open anchorOrigin={{
  vertical: 'top',
  horizontal: 'left'
}} onClose={(_e, reason) => {
  if (reason === 'timeout') {
    close();
  }
}} autoHideDuration={autoHideDuration} classes={{
  root: styles.root
}} data-sentry-element="MuiSnackbar" data-sentry-component="Snackbar" data-sentry-source-file="Snackbar.tsx">
    <MuiAlert onClose={close} severity={type} sx={{
    width: '100%'
  }} classes={{
    root: styles.alert,
    icon: styles.slotIcon,
    message: styles.slotMessage,
    action: styles.slotAction
  }} iconMapping={{
    [SnackbarType.success]: <SuccessIcon className={styles.icon} />,
    [SnackbarType.error]: <ErrorIcon className={styles.icon} />
  }} slotProps={{
    closeIcon: {
      className: styles.closeIcon
    },
    closeButton: {
      className: styles.closeButton
    }
  }} slots={{
    closeIcon: CloseIcon
  }} data-sentry-element="MuiAlert" data-sentry-source-file="Snackbar.tsx">
      {title && <Typography variant="h8" color="primary">
          {title}
        </Typography>}
      <Typography variant="paragraph10" data-sentry-element="Typography" data-sentry-source-file="Snackbar.tsx">{message}</Typography>
    </MuiAlert>
  </MuiSnackbar>;
export default Snackbar;