import React, { HTMLAttributes, useContext } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import { PostContext } from 'components/PostContentParts/PostContentParts';
import Typography from 'components/Typography';
import Button from 'components/Button';
import getStaticStormUrl from 'utils/staticStorm';
import { ReactComponent as OpenIcon } from 'public/assets/icons/open.svg';
import styles from './Restricted.module.scss';
interface RestrictedProps extends HTMLAttributes<HTMLDivElement> {
  src: string;
}
const Restricted: React.FC<RestrictedProps> = ({
  src,
  className
}) => {
  const post = useContext(PostContext);
  const hasPublicLink = post?.publicLink && post?.publicLink.length > 0;
  const t = useTranslations(`post.restricted.${hasPublicLink ? 'open_channel' : 'closed_channel'}`);
  if (!post?.isRestricted) {
    return null;
  }
  return <div className={cx(styles.root, className)} data-sentry-component="Restricted" data-sentry-source-file="Restricted.tsx">
      <Image src={getStaticStormUrl(src)} alt="restricted content" fill className={styles.image} data-sentry-element="Image" data-sentry-source-file="Restricted.tsx" />
      <div />
      <Typography variant="h8sb" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="Restricted.tsx">
        {t('title')}
      </Typography>
      <Typography variant="paragraph10" className={styles.description} data-sentry-element="Typography" data-sentry-source-file="Restricted.tsx">
        {t('subtitle')}
      </Typography>
      {hasPublicLink ? <Button variant="secondary" href={post.publicLink} target="_blank" rel="noreferrer noopener">
          <OpenIcon /> {t('button_text')}
        </Button> : <div />}
      <div />
    </div>;
};
export default Restricted;