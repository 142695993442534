// export { default } from './PostContentParts';

import dynamic from 'next/dynamic';

export { default as Root } from './PostContentParts';
export { default as DateTimeWithActions } from './DateTimeWithActions';
export { default as Metrics } from './Metrics';
export { default as TextContent } from './TextContent';
export { default as Gallery } from './Gallery';
export { default as Audio } from './Audio';
export { default as VideoCircle } from './VideoCircle';
export { default as Documents } from './Documents';
export { default as UnsupportedMessage } from './UnsupportedMessage';
export { default as MediaSlider } from './Slider';
export { default as DeletedInfo } from './DeletedInfo';
export { default as RepostInfo } from './RepostInfo';
export { default as ChannelInfo } from './ChannelInfo';
export { default as Restricted } from './Restricted';
export const Sticker = dynamic(() => import('./Sticker'), {
  loading: () => null,
  ssr: false,
});
