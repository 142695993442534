import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import cx from 'classnames';
import type { CustomTypographyProps } from 'components/Typography/types.d';
import Typography from 'components/Typography';
import styles from './DateTime.module.scss';
interface DateTimeProps {
  date: string;
  color: CustomTypographyProps['color'];
  inUTC?: boolean;
  classes?: {
    root?: string;
    divider?: string;
  };
}
dayjs.extend(utc);
const DateTime: React.FC<DateTimeProps> = ({
  date,
  inUTC = true,
  color,
  classes
}) => {
  let dayjsInstance = dayjs(date || dayjs());
  if (inUTC) {
    dayjsInstance = dayjsInstance.utc();
  }
  return <Typography component="span" variant="mono1" color={color} className={cx(styles.dateTime, classes?.root)} data-sentry-element="Typography" data-sentry-component="DateTime" data-sentry-source-file="DateTime.tsx">
      {dayjsInstance.format('DD.MM.YYYY')}
      <span className={cx(styles.dateTimeDivider, classes?.divider)} />
      {dayjsInstance.format('HH:mm')}
    </Typography>;
};
export default DateTime;