'use client';

import React, { useContext } from 'react';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import DateTime from 'components/DateTime';
import Typography from 'components/Typography';
import { ReactComponent as InfoIcon } from 'public/assets/icons/info.svg';
import { PostContext } from '../PostContentParts';
import styles from './DeletedInfo.module.scss';
interface DeletedInfoProps {
  classes?: {
    root?: string;
    info?: string;
    date?: string;
  };
}
const DeletedInfo: React.FC<DeletedInfoProps> = ({
  classes = {}
}) => {
  const t = useTranslations('post');
  const post = useContext(PostContext);
  if (!post?.isDeleted) return null;
  return <div className={cx(styles.root, classes?.root)} data-sentry-component="DeletedInfo" data-sentry-source-file="DeletedInfo.tsx">
      <Typography variant="paragraph10" className={cx(styles.info, classes?.info)} data-sentry-element="Typography" data-sentry-source-file="DeletedInfo.tsx">
        <InfoIcon className={styles.icon} data-sentry-element="InfoIcon" data-sentry-source-file="DeletedInfo.tsx" />
        {t('deleted')}
      </Typography>
      <DateTime date={post.deletionDate as string} inUTC={false} color="white" classes={{
      root: cx(styles.date, classes?.date),
      divider: styles.dateDivider
    }} data-sentry-element="DateTime" data-sentry-source-file="DeletedInfo.tsx" />
    </div>;
};
export default DeletedInfo;