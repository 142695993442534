'use client';

import React, { HTMLAttributes, useReducer } from 'react';
import ReactPlayer from 'react-player';
// import dynamic from 'next/dynamic';
import Image from 'next/image';
import { INITIAL_STATE, reducer } from './reducer';
import VideoControls from './VideoControls';
import styles from './VideoPlayer.module.scss';
interface VideoPlayerProps extends HTMLAttributes<HTMLDivElement> {
  onEnded?: () => void;
  onPlay?: () => void;
  onStart?: () => void;
  url: string;
  light: string;
  classes?: {
    root?: string;
    controls?: string;
    reset?: string;
  };
}

// const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  classes = {},
  url,
  onEnded,
  onPlay,
  onStart,
  light
}) => {
  const playerRef = React.useRef<ReactPlayer>(null);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const handlePreview = () => {
    dispatch({
      type: 'PLAY'
    });
    dispatch({
      type: 'LIGHT',
      payload: false
    });
  };
  const handlePause = () => {
    dispatch({
      type: 'PAUSE'
    });
  };
  const handlePlay = () => {
    onPlay?.();
    dispatch({
      type: 'PLAY'
    });
  };
  const handleEnded = () => {
    dispatch({
      type: 'RESET'
    });
    onEnded?.();
    playerRef.current?.showPreview();
  };
  const handleProgress = (progress: {
    playedSeconds: number;
  }) => {
    dispatch({
      type: 'SEEK',
      payload: progress.playedSeconds
    });
  };
  const handleDuration = (duration: number) => {
    dispatch({
      type: 'DURATION',
      payload: duration
    });
  };
  return <div className={classes?.root} data-sentry-component="VideoPlayer" data-sentry-source-file="VideoPlayer.tsx">
      <ReactPlayer ref={playerRef} url={url} width="100%" height="100%" light={<Image className={styles.preview} src={light} alt="video thumbnail" width={500} height={500} />} controls={state.controls} loop={state.loop} muted={state.muted} playing={state.playing} playbackRate={state.playbackRate} volume={state.volume} progressInterval={300} onStart={onStart} onPlay={handlePlay} onEnded={handleEnded} onPause={handlePause} onDuration={handleDuration} onProgress={handleProgress} onClickPreview={handlePreview} data-sentry-element="ReactPlayer" data-sentry-source-file="VideoPlayer.tsx" />
      {!state.controls && !state.light && <VideoControls dispatch={dispatch} state={state} playerRef={playerRef} classes={{
      controls: classes.controls,
      reset: classes.reset
    }} onReset={onEnded} />}
    </div>;
};
export default VideoPlayer;