import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { ButtonProps } from '@mui/material/Button';
import WaveSurfer from 'wavesurfer.js';
import { useTheme } from 'next-themes';
import cx from 'classnames';
import { PostType } from 'business/channel/repository/types.d';
import Button from 'components/Button';
import { Theme } from 'constants/theme';
import { decodeWaveform } from 'utils/audio';
import getStaticStormUrl from 'utils/staticStorm';
import convertDuration from 'utils/convertDuration';
import { ReactComponent as PlayIcon } from 'public/assets/icons/play.svg';
import { ReactComponent as PauseIcon } from 'public/assets/icons/pause.svg';
import Typography from 'components/Typography';
import { PostContext } from '../PostContentParts';
import { reducer, INITIAL_STATE } from './reducer';
import styles from './Audio.module.scss';
interface AudioProps {
  buttonProps?: ButtonProps;
  showTimings?: boolean;
  classes?: {
    root?: string;
    waverow?: string;
    button?: string;
    timings?: string;
  };
}
const Audio: React.FC<AudioProps> = ({
  classes = {},
  showTimings = false,
  buttonProps = {}
}) => {
  const post = useContext(PostContext);
  const audioMessage = post?.media && post.media.find(media => [PostType.VoiceNote, PostType.Audio].includes(media.mediaType as PostType));
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    duration: audioMessage?.duration || 0
  });
  const {
    resolvedTheme = 'light'
  } = useTheme();
  const [waveContainer, setWaveContainer] = useState<HTMLDivElement | null>(null);
  const [wavesurfer, setWaveSurfer] = useState<WaveSurfer | null>(null);
  const colors = useMemo(() => {
    if (resolvedTheme === Theme.Light) {
      return {
        waveColor: '#c5cbdb'
      };
    }
    return {
      wavecolor: 'rgba(255, 255, 255, 0.1)'
    };
  }, [resolvedTheme]);
  useEffect(() => {
    if (waveContainer && audioMessage?.url && !wavesurfer) {
      /**
       * TODO: fix problem with seekTo when custom waveform passed as peaks
       */
      const waveform = decodeWaveform(audioMessage.waveform || '');
      const wavesurfer = WaveSurfer.create({
        duration: audioMessage.duration,
        barWidth: 2,
        barGap: 2,
        barRadius: 4,
        cursorColor: 'transparent',
        progressColor: '#005ffe',
        height: 48,
        container: waveContainer,
        dragToSeek: true,
        normalize: true,
        url: getStaticStormUrl(audioMessage.url),
        ...colors,
        peaks: [waveform]
      });
      setWaveSurfer(wavesurfer);
    }
  }, [waveContainer, audioMessage, wavesurfer, colors]);
  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('play', () => dispatch({
        type: 'PLAY'
      }));
      wavesurfer.on('pause', () => dispatch({
        type: 'PAUSE'
      }));
      wavesurfer.on('timeupdate', time => dispatch({
        type: 'PROGRESS',
        payload: time
      }));
      wavesurfer.on('click', () => {
        wavesurfer.play();
        dispatch({
          type: 'PLAY'
        });
      });
      wavesurfer.on('finish', () => {
        wavesurfer.seekTo(0);
        wavesurfer.pause();
        dispatch({
          type: 'PAUSE'
        });
      });
    }
    return () => {
      wavesurfer?.unAll();
    };
  }, [wavesurfer]);
  if (!post || !audioMessage || !audioMessage.url) return null;
  const handlePlayPause = (e: React.MouseEvent) => {
    e.stopPropagation();
    // eslint-disable-next-line no-unused-expressions
    state.playing ? wavesurfer?.pause() : wavesurfer?.play();
  };
  return <div className={cx(styles.root, classes?.root)} data-sentry-component="Audio" data-sentry-source-file="Audio.tsx">
      <div className={cx(styles.row, classes?.waverow)}>
        <Button rounded variant="primary" onClick={handlePlayPause} className={classes?.button} {...buttonProps} data-sentry-element="Button" data-sentry-source-file="Audio.tsx">
          {state.playing ? <PauseIcon /> : <PlayIcon />}
        </Button>
        <div ref={el => setWaveContainer(el)} className={styles.wave} />
      </div>
      {showTimings && <div className={cx(styles.timings, classes?.timings)}>
          <Typography variant="h10bk">
            {convertDuration(state.progress.playedSeconds)} / {convertDuration(state.duration)}
          </Typography>
        </div>}
    </div>;
};
export default Audio;