import React from 'react';
import cx from 'classnames';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import { ReactComponent as InfoIcon } from 'public/assets/icons/info.svg';
import type { TextInputProps } from './types.d';
import styles from './Text.module.scss';
const TextInput: React.FC<TextInputProps> = ({
  input,
  meta,
  classes,
  variant = 'primary',
  size = 'm',
  ...otherProps
}) => {
  const {
    InputProps,
    ...textFieldProps
  } = otherProps;
  const isError = meta.touched && typeof (meta.error ?? meta.submitError) !== 'undefined';
  const rootClassNames = cx(styles.root, classes?.root);
  const inputClassNames = cx(styles.input, classes?.input, {
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
    [styles.medium]: size === 'm',
    [styles.large]: size === 'l',
    [styles.error]: isError
  });
  return <TextField type="text" classes={{
    root: rootClassNames
  }} InputProps={{
    ...InputProps,
    classes: {
      input: inputClassNames,
      error: styles.error
    },
    endAdornment: <InputAdornment position="end">
            {isError && <Tooltip title={meta.touched && meta.error || meta.submitError} enterTouchDelay={0} leaveTouchDelay={7000} arrow placement="top">
                <div>
                  <Button variant="secondary" size="medium" rounded className={styles.errorIcon}>
                    <InfoIcon />
                  </Button>
                </div>
              </Tooltip>}
            {InputProps?.endAdornment}
          </InputAdornment>
  }} InputLabelProps={{
    classes: {
      root: styles.label,
      filled: styles.labelFilled,
      focused: styles.labelFocused,
      disabled: styles.labelDisabled,
      error: styles.labelError
    },
    shrink: true,
    margin: undefined,
    required: false
  }} FormHelperTextProps={{
    classes: {
      root: styles.formHelperText
    }
  }} error={isError} helperText={meta.touched && meta.error || meta.submitError} {...textFieldProps} {...input} data-sentry-element="TextField" data-sentry-component="TextInput" data-sentry-source-file="Text.tsx" />;
};
export default TextInput;