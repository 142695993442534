'use client';

import React, { useContext, useMemo, useState } from 'react';
import SlickSlider, { Settings } from '@ant-design/react-slick';
import cx from 'classnames';
import { PostType } from 'business/channel/repository/types.d';
import Button from 'components/Button';
import Typography from 'components/Typography';
import VideoPlayer from 'components/VideoPlayer';
import ImageWithErrorState from 'components/ImageWithErrorState';
import getStaticStormUrl from 'utils/staticStorm';
import { ReactComponent as ArrowLeft } from 'public/assets/icons/arrow-l.svg';
import { ReactComponent as ArrowRight } from 'public/assets/icons/arrow-r.svg';
import { PostContext } from '../PostContentParts';
import styles from './Slider.module.scss';
const MediaSliderTypes = [PostType.Video, PostType.Photo] as string[];
const Slider: React.FC = () => {
  const post = useContext(PostContext);
  const [activeSlide, setActiveSlide] = useState(1);
  const [isVideoPlayerActive, setIsVideoPlayerActive] = useState(false);
  const medias = post?.media.filter(media => MediaSliderTypes.includes(media.mediaType));
  const settings: Settings = useMemo(() => ({
    fade: true,
    dots: !isVideoPlayerActive,
    arrows: !isVideoPlayerActive,
    infinite: false,
    nextArrow: <Button variant="light" size="medium" rounded className={styles.next}>
          <ArrowRight />
        </Button>,
    prevArrow: <Button variant="light" size="medium" rounded className={styles.prev}>
          <ArrowLeft />
        </Button>,
    afterChange(currentSlide: number) {
      setActiveSlide(currentSlide + 1);
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    customPaging(i) {
      if (!medias) return <div />;
      const mediaToRender = medias[i];
      const mediaUrl = mediaToRender.thumbnailUrl || mediaToRender.url;
      if (!mediaUrl) return <div />;
      return <div key={mediaToRender.uniqueId} className={styles.previewImage}>
            <ImageWithErrorState showErrorMessage={false} src={`${getStaticStormUrl(mediaUrl)}?w=40&h=40`} width={40} height={40} alt="image preview" />
            <div className={styles.border} />
          </div>;
    }
  }), [medias, isVideoPlayerActive]);
  if (!post || !medias) return null;
  return <div className={styles.root} data-sentry-component="Slider" data-sentry-source-file="Slider.tsx">
      <SlickSlider {...settings} data-sentry-element="SlickSlider" data-sentry-source-file="Slider.tsx">
        {medias.map(media => {
        const mediaUrl = media.thumbnailUrl || media.url;
        const isVideo = media.mediaType === PostType.Video;
        if (isVideo && media.url && media.thumbnailUrl) {
          return <>
                <ImageWithErrorState src={`${getStaticStormUrl(media.thumbnailUrl)}?b=50`} alt="Vedio thumbnail" {...!media.url && {
              presetIsError: true
            }} width={400} height={400} className={cx(styles.itemBG, styles.video)} />
                <VideoPlayer classes={{
              controls: styles.videoControls,
              reset: styles.videoReset
            }} onPlay={() => setIsVideoPlayerActive(true)} onEnded={() => setIsVideoPlayerActive(false)} url={getStaticStormUrl(media.url)} light={getStaticStormUrl(media.thumbnailUrl)} key={media.uniqueId} className={styles.media} />
              </>;
        }
        return <div key={media.uniqueId} className={styles.media}>
              {mediaUrl && <>
                  <ImageWithErrorState showErrorMessage={false} src={`${getStaticStormUrl(mediaUrl)}?b=10`} alt="Post media" className={styles.itemBG} width={512} height={512} />
                  <ImageWithErrorState showErrorMessage classes={{
              error: styles.error
            }} src={getStaticStormUrl(mediaUrl)} alt="Post media" className={styles.item} width={512} height={512} />
                </>}
            </div>;
      })}
      </SlickSlider>
      {!isVideoPlayerActive && <div className={styles.counter}>
          <Typography variant="h10bk" color="white">
            {activeSlide}/{medias.length}
          </Typography>
        </div>}
    </div>;
};
export default Slider;