'use client';

/* eslint-disable no-case-declarations */
import React, { createContext, HTMLAttributes, useCallback, useMemo, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import Snackbar, { SnackbarType } from 'components/Snackbar';
export type ISnackbarOpenProps = {
  title?: React.ReactNode | null;
  message: React.ReactNode;
  type?: SnackbarType;
  autoHideDuration?: null | number;
  showAction?: boolean;
};
export interface ISnackbarContext {
  open({
    title,
    message,
    type,
    autoHideDuration,
    showAction
  }: ISnackbarOpenProps): void;
  wipe(): void;
}
export const SnackbarContext = createContext<ISnackbarContext>({
  open: () => {},
  wipe: () => {}
});
type ISnackbar = Required<ISnackbarOpenProps>;
type ISnackbarState = {
  snackbars: ({
    id: string;
  } & ISnackbar)[];
};
type SnackbarAction = {
  type: 'OPEN';
  payload: {
    id: string;
  } & ISnackbar;
} | {
  type: 'CLOSE';
  payload: {
    id: string;
  };
} | {
  type: 'WIPE';
};
const initialSnackbarState = {
  snackbars: []
};
const snackbarReducer = (state: ISnackbarState, action: SnackbarAction) => {
  switch (action.type) {
    case 'OPEN':
      const {
        id,
        title,
        message,
        type,
        showAction,
        autoHideDuration
      } = action.payload;
      const [_toRemove, ...stack] = state.snackbars;
      const snackbarStack = state.snackbars.length > 4 ? stack : state.snackbars;
      return {
        ...state,
        snackbars: [...snackbarStack, {
          id,
          title,
          message,
          type,
          showAction,
          autoHideDuration
        }]
      };
    case 'CLOSE':
      const {
        id: removeId
      } = action.payload;
      return {
        snackbars: [...state.snackbars.filter(({
          id: snackbarId
        }) => snackbarId !== removeId)]
      };
    case 'WIPE':
      return {
        snackbars: []
      };
    default:
      return {
        ...state
      };
  }
};
export const SnackbarContextProvider: React.FC<HTMLAttributes<HTMLElement>> = ({
  children
}) => {
  const [snackbarState, dispatch] = useReducer(snackbarReducer, initialSnackbarState);
  const open = useCallback(({
    message,
    type = SnackbarType.error,
    autoHideDuration = 6000,
    title = null,
    showAction = false
  }: ISnackbarOpenProps) => {
    dispatch({
      type: 'OPEN',
      payload: {
        id: uuid(),
        title,
        message,
        type,
        autoHideDuration,
        showAction
      }
    });
  }, []);
  const close = useCallback((id: string) => {
    dispatch({
      type: 'CLOSE',
      payload: {
        id
      }
    });
  }, []);
  const wipe = useCallback(() => {
    dispatch({
      type: 'WIPE'
    });
  }, []);
  const values = useMemo(() => ({
    open,
    wipe
  }), [open, wipe]);
  return <SnackbarContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="SnackbarContextProvider" data-sentry-source-file="SnackbarContext.tsx">
      {snackbarState.snackbars.map(snackbar => <Snackbar {...snackbar} close={() => close(snackbar.id)} key={snackbar.id} />)}
      {children}
    </SnackbarContext.Provider>;
};