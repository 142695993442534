import React, { ComponentProps, HTMLAttributes, useContext } from 'react';
import { useParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import { ButtonProps } from '@mui/material/Button';
import Button from 'components/Button';
import DateTime from 'components/DateTime';
import PopperTrigger from 'components/PopperTrigger';
import { SnackbarContext } from 'contexts/SnackbarContext';
import { SnackbarType } from 'components/Snackbar';
import { ReactComponent as OpenIcon } from 'public/assets/icons/open.svg';
import { ReactComponent as ShareIcon } from 'public/assets/icons/share.svg';
import { PostContext } from '../PostContentParts';
import Share from './Share';
import styles from './DateTimeWithActions.module.scss';
export interface DateTimeWithActionsProps extends HTMLAttributes<HTMLDivElement> {
  openProps?: Partial<ButtonProps>;
  shareProps?: Partial<ButtonProps>;
  typographyProps?: Partial<ComponentProps<typeof DateTime>>;
  withoutActions?: boolean;
}
const DateTimeWithActions: React.FC<DateTimeWithActionsProps> = ({
  className,
  withoutActions = false,
  typographyProps = {},
  ...rest
}) => {
  const t = useTranslations('post.copy');
  const post = useContext(PostContext);
  const {
    open: openSnackbar
  } = useContext(SnackbarContext);
  const params = useParams();
  if (!post) {
    return null;
  }
  const handleClosePopper = (isCopied: boolean) => {
    if (isCopied) {
      openSnackbar({
        message: t('alert'),
        type: SnackbarType.success
      });
    }
  };
  return <div className={cx(styles.root, className)} {...rest} data-sentry-component="DateTimeWithActions" data-sentry-source-file="DateTimeWithActions.tsx">
      {!!post.publishDate && <DateTime date={post.publishDate} inUTC={false} color="primary" {...typographyProps} />}
      {!withoutActions && <>
          <Button href={post.publicLink} rel="noopener noreferrer" target="_blank" className={styles.action} variant="text" type="button" iconned>
            <OpenIcon className={styles.actionIcon} />
          </Button>
          <PopperTrigger closeFnAsPropName="onCopy" onClose={handleClosePopper} popperContent={<Share onCopy={undefined as never} postLink={`${process.env.NEXT_PUBLIC_WEB_HOST}/channel/${params.id}/${post?.id}`} />}>
            <Button className={styles.action} type="button" iconned>
              <ShareIcon className={styles.actionIcon} />
            </Button>
          </PopperTrigger>
        </>}
    </div>;
};
export default DateTimeWithActions;