import React from 'react';
import { useTranslations } from 'next-intl';
import { Field, Form } from 'react-final-form';
import Button from 'components/Button';
import Typography from 'components/Typography';
import TextInput from 'components/Input/Text/Text';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { ReactComponent as CopyIcon } from 'public/assets/icons/copy.svg';
import styles from './Share.module.scss';
interface ShareProps {
  postLink: string;
  onCopy: (e: MouseEvent | TouchEvent, result: boolean) => void;
}
const Share: React.FC<ShareProps> = ({
  onCopy,
  postLink
}) => {
  const t = useTranslations('post.copy');
  const [, copy] = useCopyToClipboard();
  const handleCopy = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    copy(postLink).then(result => {
      onCopy(e, result);
    });
  };

  /**
   * Somehow, when we click on the typography or root,
   * the post block receive the click event
   * and trigger modal opening.
   *
   * P.S. Popper renders in portal
   */
  const handleClickRoot = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    // @ts-ignore
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={styles.root} onClick={handleClickRoot} data-sentry-component="Share" data-sentry-source-file="Share.tsx">
      <Typography variant="paragraph10" data-sentry-element="Typography" data-sentry-source-file="Share.tsx">{t('message')}</Typography>
      <div className={styles.link}>
        <Form initialValues={{
          postLink
        }} onSubmit={() => {}} render={({
          handleSubmit
        }) => <form onSubmit={handleSubmit} className={styles.form}>
              <Field name="postLink" component={TextInput} disabled size="m" variant="primary" classes={{
            input: styles.input
          }} />
            </form>} data-sentry-element="Form" data-sentry-source-file="Share.tsx" />
        <Button variant="secondary" onClick={handleCopy} rounded data-sentry-element="Button" data-sentry-source-file="Share.tsx">
          <CopyIcon data-sentry-element="CopyIcon" data-sentry-source-file="Share.tsx" />
        </Button>
      </div>
    </div>
  );
};
export default Share;