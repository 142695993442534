import React, { useContext } from 'react';
import { useTranslations } from 'next-intl';
import ReactHtmlParser from 'react-html-parser';
import { PostType } from 'business/channel/repository/types.d';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { ReactComponent as InfoIcon } from 'public/assets/icons/info.svg';
import { ReactComponent as OpenIcon } from 'public/assets/icons/open.svg';
import { PostContext } from '../PostContentParts';
import styles from './UnsupportedMessage.module.scss';
const UnsupportedMessage: React.FC = () => {
  const post = useContext(PostContext);
  const t = useTranslations('post.media.unsupported');
  if (!post || post.type !== PostType.Unsupported) return null;
  return <div className={styles.root} data-sentry-component="UnsupportedMessage" data-sentry-source-file="UnsupportedMessage.tsx">
      <InfoIcon className={styles.icon} data-sentry-element="InfoIcon" data-sentry-source-file="UnsupportedMessage.tsx" />
      <Typography variant="h12" align="center" data-sentry-element="Typography" data-sentry-source-file="UnsupportedMessage.tsx">
        {ReactHtmlParser(t('message'))}
      </Typography>
      <Button href={post.publicLink} target="_blank" rel="noopener noreferrer" variant="primary" startIcon={<OpenIcon fill="#ffffff" width={20} height={20} />} data-sentry-element="Button" data-sentry-source-file="UnsupportedMessage.tsx">
        {t('link')}
      </Button>
    </div>;
};
export default UnsupportedMessage;