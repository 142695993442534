import React, { ComponentProps, HTMLAttributes, useContext } from 'react';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import Typography from 'components/Typography';
import Button from 'components/Button';
import ChannelAvatar from 'components/Avatar/Channel';
import { Link } from 'utils/navigation';
import { PostContext } from '../PostContentParts';
import styles from './RepostInfo.module.scss';
interface RepostInfoProps extends HTMLAttributes<HTMLDivElement> {
  classes?: {
    root?: string;
    button?: string;
  };
  avatarProps?: Partial<ComponentProps<typeof ChannelAvatar>>;
}
const RepostInfo: React.FC<RepostInfoProps> = ({
  className,
  classes,
  avatarProps = {}
}) => {
  const t = useTranslations('post');
  const post = useContext(PostContext);
  if (!post || post?.originChannel === null) return null;
  const {
    originChannel: channel
  } = post;
  return <div className={cx(styles.root, className, classes?.root)} data-sentry-component="RepostInfo" data-sentry-source-file="RepostInfo.tsx">
      <Typography variant="paragraph10" className={styles.text} data-sentry-element="Typography" data-sentry-source-file="RepostInfo.tsx">
        {t('reposted')}:
      </Typography>
      <Button variant="secondary" className={cx(styles.button, classes?.button)} href={{
      pathname: '/channel/[id]',
      params: {
        id: channel?.muid
      }
    }} component={Link} data-sentry-element="Button" data-sentry-source-file="RepostInfo.tsx">
        <ChannelAvatar size="24" name={channel.title} verified={channel.isVerified} src={channel?.profileImage?.url} alt={`${channel.title} avatar`} {...avatarProps} data-sentry-element="ChannelAvatar" data-sentry-source-file="RepostInfo.tsx" />
        <Typography variant="h12bk" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="RepostInfo.tsx">
          {channel.title}
        </Typography>
      </Button>
    </div>;
};
export default RepostInfo;