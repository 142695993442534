import React, { ReactNode, useCallback, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import Typography from 'components/Typography';
import { CustomTypographyProps } from 'components/Typography/types.d';
import { ReactComponent as PlayIcon } from 'public/assets/icons/play.svg';
import { ReactComponent as InfoIcon } from 'public/assets/icons/info.svg';
import styles from './ImageWithErrorState.module.scss';
interface ImageWithErrorStateProps extends ImageProps {
  isVideo?: boolean;
  showErrorMessage?: boolean;
  errorMessage?: ReactNode;
  presetIsError?: boolean;
  moreMediaCount?: number;
  moreMediaProps?: {
    typographyVariant?: CustomTypographyProps['variant'];
  };
  classes?: {
    root?: string;
    error?: string;
    more?: string;
  };
}
const ImageWithErrorState: React.FC<ImageWithErrorStateProps> = ({
  isVideo,
  showErrorMessage,
  errorMessage,
  presetIsError = false,
  moreMediaCount,
  moreMediaProps,
  classes = {},
  ...restProps
}) => {
  const {
    className: imageClassName,
    ...imageProps
  } = restProps;
  const t = useTranslations();
  const [isError, setIsError] = useState(presetIsError);
  const handleError = useCallback(() => {
    setIsError(true);
  }, [setIsError]);
  return <div className={cx(styles.root, classes?.root, {
    [styles.errored]: isError
  })} data-sentry-component="ImageWithErrorState" data-sentry-source-file="ImageWithErrorState.tsx">
      {isError ? <div className={cx(styles.error, classes?.error)}>
          <InfoIcon />
          {showErrorMessage && <Typography variant="h12">{errorMessage || t('media.error')}</Typography>}
        </div> : <>
          <Image onError={handleError} className={cx(styles.image, imageClassName)} {...imageProps} />
          {isVideo && <div className={styles.play}>
              <PlayIcon />
            </div>}
          {typeof moreMediaCount !== 'undefined' && moreMediaCount > 0 && <div className={cx(styles.more, classes?.more)}>
              <Typography variant={moreMediaProps?.typographyVariant || 'h12bk'} color="white">
                +{moreMediaCount}
              </Typography>
            </div>}
        </>}
    </div>;
};
export default ImageWithErrorState;