import { AdsPostRole } from 'business/types.d';

export enum MetricType {
  MembersCount = 'MembersCount',
  CitationIndex = 'CitationIndex',
  MentionsCount = 'MentionsCount',
  RepostsCount = 'RepostsCount',
  ChannelsRepostsCount = 'ChannelsRepostsCount',
  RepostsOnChannelsCount = 'RepostsOnChannelsCount',
  MentionsOnChannelsCount = 'MentionsOnChannelsCount',
  ChannelsMentionsCount = 'ChannelsMentionsCount',
  FemalesCount = 'FemalesCount',
  MalesCount = 'MalesCount',
  PostsViewAvgCount = 'PostsViewAvgCount',
  PostViewsAvgBy12hFromPosting = 'PostViewsAvgBy12hFromPosting',
  PostViewsAvgBy24hFromPosting = 'PostViewsAvgBy24hFromPosting',
  PostViewsAvgBy48hFromPosting = 'PostViewsAvgBy48hFromPosting',
  PostsERAvg = 'PostsERAvg',
  PostsRepostsAvgCount = 'PostsRepostsAvgCount',
  PostsCommentsAvgCount = 'PostsCommentsAvgCount',
  PostsReactionsAvgCount = 'PostsReactionsAvgCount',
  PostsERRAvg = 'PostsERRAvg',
  AdsPostsViewAvgCount = 'AdsPostsViewAvgCount',
  AdsPostViewsAvgBy0to1hFromPosting = 'AdsPostViewsAvgBy0to1hFromPosting',
  AdsPostViewsAvgBy1to4hFromPosting = 'AdsPostViewsAvgBy1to4hFromPosting',
  AdsPostViewsAvgBy4to24hFromPosting = 'AdsPostViewsAvgBy4to24hFromPosting',
  PostsCount = 'PostsCount',
  AdsCount = 'AdsCount',
  Rating = 'Rating',
  RatingScore = 'RatingScore',
}

export enum QueryType {
  LastValue = 'LastValue',
  LastValues = 'LastValues',
  MaxValue = 'MaxValue',
  Range = 'Range',
}

export enum ValueType {
  AbsoluteValue = 'AbsoluteValue',
  Change = 'Change',
  PercentChange = 'PercentageChange',
  PercentageAbsoluteValue = 'PercentageAbsoluteValue',
}

export enum Period {
  Day = 'Day',
  DayRelative = 'Day_relative',
  Week = 'Week',
  WeekRelative = 'Week_relative',
  Month = 'Month',
  MonthRelative = 'Month_relative',
}

export type TMedia = {
  type: string;
  url: string;
  width: number;
  height: number;
  size: number;
};

export type TSocial = {
  platform: string;
  url: string;
};

export type TMetric = {
  metric: MetricType;
  queryType: QueryType;
  period?: Period;
  valueType: ValueType;
  current: {
    timestamp: string;
    value: number;
  };
  value?: {
    timestamp: string;
    value: number;
  };
};

export type TSeriesPoint = {
  timestamp: string;
  value: number;
};

export type TSeries = {
  metric: MetricType;
  queryType: QueryType;
  period: Period;
  valueType: ValueType;
  startDate: string;
  endDate: string;
  series: TSeriesPoint[];
};

export type TFilters = Partial<{
  startDate: string;
  endDate: string;
  limit: number;
  offset: number;
  order: 'asc' | 'desc';
  role: AdsPostRole;
}>;

export type TAdsEfficiencyFilters = TFitlers & {
  type: 'all' | 'repost' | 'mention';
  sort: 'isMention' | 'sendDate' | 'title' | 'views' | 'membersGrowth' | 'concurrentAds';
  hideNonEmptyConcurrentAds?: boolean;
};

export enum PostType {
  Text = 'messageText',
  Photo = 'messagePhoto',
  Video = 'messageVideo',
  Audio = 'messageAudio',
  VoiceNote = 'messageVoiceNote',
  Animation = 'messageAnimation',
  VideoNote = 'messageVideoNote',
  PinMessage = 'messagePinMessage',
  Document = 'messageDocument',
  Poll = 'messagePoll',
  Sticker = 'messageSticker',
  Unsupported = 'messageUnsupported',
  CustomServiceAction = 'messageCustomServiceAction',
}

export type TPost = {
  id: string;
  type: PostType;
  text: string | null;
  views: number | null;
  forwards: number | null;
  replies: number | null;
  reactions: number | null;
  publishDate: string;
  editDate: string | null;
  isDeleted: boolean;
  isAds: boolean;
  isRestricted?: boolean;
  publicLink: string;
  deletionDate: string | null;
  media: {
    uniqueId: string;
    mediaType: string;
    url: string | null;
    thumbnailUrl?: string;
    duration?: number;
    size?: number;
    waveform?: string;
  }[];
  buttons: Record<string, string>[] | null;
  originChannel: {
    muid: string;
    title: string;
    isVerified: boolean;
    profileImage: TMedia;
  } | null;
  channel?: {
    id: string;
    title: string;
    isVerified: boolean;
    profileImage: TMedia;
  };
};

export type TAdsPost = {
  TOPDuration: number;
  subscriberGrowth: number;
  promotedChannelInformation: {
    id: string;
    title: string;
    members: number;
    category: {
      id: string;
      title: string;
    }[];
    profileImage: TMedia;
    isVerified: boolean;
  };
  promotionalPostInformation: {
    id: string;
    viewCount: number;
    totalRepostCount: number;
    reactionsCount: number;
    publishDate: string;
  };
};

export type TChannelPostsStatisticsPoint = {
  date: string;
  posts: number;
  reposts: number;
  mentions: number;
  members: number;
  membersGrowth: number;
};

export type TChannelPostsStatistics = {
  statistics: TChannelPostsStatisticsPoint[];
  startingDate: string;
};

type TAdsEfficiencyConcurrentData = {
  total: number;
  series: {
    id: string;
    title: string;
    views: number;
    members: number;
    messageId: string;
    isVerified: boolean | null;
    profileImage: TMedia;
  }[];
} | null;

export type TAdsEfficiencyRecord = {
  id: string;
  isAds: boolean;
  date: string;
  membersGrowth: number;
  views: number;
  top: number;
  promotionChannelData: {
    id: string;
    title: string;
    isVerified: boolean | null;
    members: number;
    invite_links: string[];
    username: string;
    category:
      | {
          id: string;
          title: string;
        }[]
      | null;
    profileImage: TMedia;
  };
  concurrentAdsData: TAdsEfficiencyConcurrentData;
  concurrentRepostsData: TAdsEfficiencyConcurrentData;
};

export type TAdsEfficiencyResponse = {
  series: TAdsEfficiencyRecord[];
  total: number;
};

export type AdsEfficiencyGroupedRecord = {
  top: number;
  id: string;
  isAds: boolean;
  views: number;
  date: string;
  members: number;
  membersGrowth: number;
  concurrentAdsData: TAdsEfficiencyConcurrentData;
  concurrentRepostsData: TAdsEfficiencyConcurrentData;
};

export type AdsEfficiencyGroupedSeriesPoint = {
  id: string;
  invite_links: string[];
  username: string;
  title: string;
  category:
    | {
        id: string;
        title: string;
      }[]
    | null;
  isVerified: boolean;
  profileImage: TMedia;
  total: number;
  maxViews: number;
  totalMembersGrowth: number;
  maxMembersGrowth: number;
  concurrentMessagesLength: number;
  adsMessagesInfo: AdsEfficiencyGroupedRecord[];
};

export type TAdsEfficiencyGroupedResponse = {
  series: AdsEfficiencyGroupedSeriesPoint[];
  total: number;
};

/**
 * SHOULD BE ONLY REQUEST/RESPONSE TYPES
 */
export type TBaseChannelInfo = {
  type: 'Channel' | 'Group' | 'Chat';
  id: string;
  title: string;
  username: string;
  isVerified: boolean;
  isPublic: boolean;
  isScamOrFake: boolean;
  isConfirmed: boolean;
  profileImage: TMedia;
  category:
    | {
        id: string;
        title: string;
      }[]
    | null;
};

export type TCountry = {
  id: string;
  title: string;
};

export type TChannelInfoResponse = TBaseChannelInfo & {
  joinLink: {
    url: string;
    isExpired: boolean;
  } | null;
  description: string;
  lang: string;
  locale: {
    id: string;
    title: string;
  } | null;
  foundedDate: string | null;
  addedDate: string;
  country: TCountry | null;
  linkedChat: (TBaseChannelInfo & { members: number }) | null;
  region: {
    id: string;
    title: string;
    country: TCountry | null;
  } | null;
  adsManagers: {
    username: string;
    profileImage: TMedia;
  }[];
  socials: TSocial[];
};

export type TChannelSettings = {
  countryId?: string | null;
  localeId?: string | null;
  categoryId?: string | null;
  socials?: TSocial[];
};

export interface TNativeStatisticsResponse {
  period?: {
    start: string;
    end: string;
  };
  memberCount?: TNativeStatisticsOverview;
  meanMessageViewCount?: TNativeStatisticsOverview;
  meanMessageShareCount?: TNativeStatisticsOverview;
  meanMessageReactionCount?: TNativeStatisticsOverview;
  meanStoryViewCount?: TNativeStatisticsOverview;
  meanStoryShareCount?: TNativeStatisticsOverview;
  meanStoryReactionCount?: TNativeStatisticsOverview;
  storyReactionGraph?: StoryReactionGraph[];
  storyInteractionGraph?: StoryInteractionGraph[];
  messageReactionGraph?: MessageReactionGraph[];
  memberCountGraph?: MemberCountGraph[];
  joinGraph?: JoinGraph[];
  joinBySourceGraph?: JoinBySourceGraph[];
  languageGraph?: LanguageGraph[];
  muteGraph?: MuteGraph[];
  viewCountByHourGraph?: ViewCountByHourGraph[];
  viewCountBySourceGraph?: ViewCountBySourceGraph[];
  messageInteractionGraph?: MessageInteractionGraph[];
  membersOnlineCount?: MembersOnlineCount;
  membersGendersCount?: MembersGendersCount;
  membersJoinCount?: MembersJoinCount;
}

export interface MembersGendersCount {
  female: number;
  male: number;
  unrecognized: number;
}

export interface MembersOnlineCount {
  online: number;
  from2To7: number;
  from7To30: number;
  unrecognized: number;
  moreThanMonth: number;
  recentlyOnline: number;
}

export interface MembersJoinCount {
  week: number;
  month: number;
  year: number;
  other: number;
}

export interface StoryReactionGraph {
  timestamp: string;
  Positive?: number;
  Negative?: number;
  Other?: number;
}

export interface StoryInteractionGraph {
  timestamp: string;
  Views?: number;
  Shares?: number;
}

export interface MessageReactionGraph {
  timestamp: string;
  Positive?: number;
  Other?: number;
  Negative?: number;
}

export interface MemberCountGraph {
  timestamp: string;
  'Total followers'?: number;
}

export interface JoinGraph {
  timestamp: string;
  Joined?: number;
  Left?: number;
}

export interface JoinBySourceGraph {
  timestamp: string;
  PM?: number;
  Groups?: number;
  'Similar Channels'?: number;
  Search?: number;
  Channels?: number;
  URL?: number;
  Ads?: number;
  'Shareable Chat Folders'?: number;
}

export interface LanguageGraph {
  timestamp: string;
  Ukrainian?: number;
  Russian?: number;
  English?: number;
  German?: number;
  Polish?: number;
  Spanish?: number;
  Italian?: number;
}

export interface MuteGraph {
  timestamp: string;
  Muted?: number;
  Unmuted?: number;
}

export interface ViewCountByHourGraph {
  timestamp: string;
  [key: string]: number;
}

export interface ViewCountBySourceGraph {
  timestamp: string;
  Followers?: number;
  PM?: number;
  Groups?: number;
  Channels?: number;
  Other?: number;
  Search?: number;
  'Similar Channels'?: number;
  URL?: number;
  Ads?: number;
}

export interface MessageInteractionGraph {
  timestamp: string;
  Views?: number;
  Shares?: number;
}

export enum THistoryFilter {
  Username = 'username',
  Category = 'categoryId',
  Locale = 'localeId',
  Title = 'title',
  Description = 'description',
  HasLinkedChat = 'has_linked_chat',
  HasLocation = 'has_location',
  Location = 'location',
  IsScam = 'is_scam',
  IsFake = 'is_fake',
  Photo = 'photo',
  IsVerified = 'is_verified',
  IsBroadcastGroup = 'is_broadcast_group',
  LinkedChat = 'linked_chat_id',
  IsChannel = 'is_channel',
}

export type THistoryResponse = {
  history: {
    id: number;
    attribute: string;
    value: [string];
    nextValue: [string];
    date: string;
  }[];
  total: number;
};
