import { createContext, ReactNode } from 'react';
import { TPost } from 'business/channel/repository';
import { PostType } from 'business/channel/repository/types.d';
const MediaGalleryTypes = [PostType.Video, PostType.Photo] as string[];
export const PostContext = createContext<(TPostEntity & {
  highlights?: string[];
}) | null>(null);
type TPostEntity = TPost & {
  channelId: string;
};
interface Props {
  post: TPostEntity;
  children: ((args: {
    hasGallery: boolean;
    noContentExceptGallery: boolean;
    isDeleted: boolean;
    isRepost: boolean;
    isRestricted: boolean;
    hasChannelInfo: boolean;
  }) => ReactNode) | ReactNode;
}
const Post: React.FC<Props> = ({
  post,
  children
}) => {
  const noContentExceptGallery = !post.text && !!post.media.length && post.media.every(media => MediaGalleryTypes.includes(media.mediaType));
  const hasGallery = post.media.some(media => MediaGalleryTypes.includes(media.mediaType));
  const isRepost = post.originChannel !== null;
  const hasChannelInfo = !!post.channel;
  const isRestricted = post.isRestricted === true;
  const child = typeof children === 'function' ? children({
    hasGallery,
    noContentExceptGallery,
    isDeleted: post.isDeleted,
    isRepost,
    isRestricted,
    hasChannelInfo
  }) : children;
  return <PostContext.Provider value={post} data-sentry-element="unknown" data-sentry-component="Post" data-sentry-source-file="PostContentParts.tsx">{child}</PostContext.Provider>;
};
export default Post;