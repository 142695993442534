import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import type { ButtonProps } from '@mui/material/Button';
import Button from 'components/Button';
import Typography from 'components/Typography';
import type { CustomTypographyProps } from 'components/Typography/types.d';
import useToggle from 'hooks/useToggle';
import { Link } from 'utils/navigation';
import { ReactComponent as ArrowRight } from 'public/assets/icons/arrow-r.svg';
import { PostContext } from '../PostContentParts';
import htmlRenderer from './htmlRenderer';
import styles from './TextContent.module.scss';
interface TextContentProps extends CustomTypographyProps {
  lineClamp?: number;
  shouldShowReadMore?: boolean;
  readMoreProps?: ButtonProps;
  classes?: {
    buttons?: string;
  };
}
const TextContent: React.FC<TextContentProps> = ({
  children,
  lineClamp,
  shouldShowReadMore = true,
  className,
  classes = {},
  readMoreProps: {
    className: readMoreClassName,
    ...readMoreProps
  } = {},
  ...rest
}) => {
  const t = useTranslations('post');
  const post = useContext(PostContext);
  const [rootRef, setRootRef] = useState<HTMLSpanElement | null>(null);
  const [isReadMoreShown, showReadMore] = useToggle(false);
  useEffect(() => {
    if (shouldShowReadMore && rootRef && lineClamp) {
      if ((rootRef.offsetHeight < rootRef.scrollHeight || rootRef.offsetWidth < rootRef.scrollWidth) && !isReadMoreShown) {
        showReadMore();
      }
    }
  }, [rootRef, isReadMoreShown, lineClamp, showReadMore, shouldShowReadMore]);
  const text = useMemo(() => {
    if (post?.highlights?.length) {
      return (post?.text || '').replaceAll(new RegExp(post.highlights.join('|').replace(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&'), 'gi'), entry => {
        return `<span className="${styles.highlight}">${entry}</span>`;
      });
    }
    return post?.text;
  }, [post?.highlights, post?.text]);
  if (!post?.text && !post?.buttons?.length) return null;
  return <>
      {text && <Typography typographyRef={setRootRef} component="p" variant="paragraph10" className={cx(className, styles.root, {
      [styles.clamped]: lineClamp && lineClamp > 0
    })} {...lineClamp && {
      style: {
        WebkitLineClamp: lineClamp || 'unset'
      }
    }} {...rest}>
          {htmlRenderer(text)}
        </Typography>}
      {!isReadMoreShown && (post.buttons || [])?.length > 0 && <div className={cx(styles.inlineButtons, classes?.buttons)}>
          {(post.buttons || []).map(button => {
        const title = Object.keys(button).at(0);
        const url = Object.values(button).at(0);
        return <Button variant="secondary" size="medium" href={url} key={`${post.id}-${title}-${url}`} target="_blank" rel="noopener noreferrer">
                {title}
              </Button>;
      })}
        </div>}
      {isReadMoreShown && <Button variant="text" size="small" href={{
      pathname: '/channel/[id]/[postId]',
      params: {
        id: post.channelId,
        postId: post.id
      }
    }} component={Link} scroll={false} className={cx(readMoreClassName, styles.readMore)} {...readMoreProps}>
          <Typography variant="h10bk">{t('readMore')}</Typography>
          <ArrowRight className={styles.icon} />
        </Button>}
    </>;
};
export default TextContent;