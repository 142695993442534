import React, { HTMLAttributes, useContext } from 'react';
import prettyBytes from 'pretty-bytes';
import cx from 'classnames';
import { PostType } from 'business/channel/repository/types.d';
import Tag from 'components/Tag';
import Typography from 'components/Typography';
import getStaticStormUrl from 'utils/staticStorm';
import { ReactComponent as DocumentIcon } from 'public/assets/icons/document.svg';
import { PostContext } from '../PostContentParts';
import styles from './Documents.module.scss';
interface DocumentsProps extends HTMLAttributes<HTMLDivElement> {
  withoutSize?: boolean;
}
const Documents: React.FC<DocumentsProps> = ({
  className,
  withoutSize = false,
  ...props
}) => {
  const post = useContext(PostContext);
  const documents = post?.media.filter(media => media.mediaType === PostType.Document);
  if (!documents?.length) return null;
  return <div className={cx(styles.root, className)} {...props} data-sentry-component="Documents" data-sentry-source-file="Documents.tsx">
      {documents?.map(document => {
      /**
       * TODO: handle case when some documents not uploaded to static storm
       */
      if (!document.url) return null;
      return <a key={document.uniqueId} href={getStaticStormUrl(document.url)} target="_blank" className={styles.document} download={document.uniqueId} rel="noreferrer noopener">
            <div className={styles.icon}>
              <DocumentIcon />
            </div>
            <Typography variant="h10" color="primary" className={styles.name}>
              {document.uniqueId}
            </Typography>
            {!withoutSize && <Tag className={styles.tag}>{prettyBytes(document.size || 0)}</Tag>}
          </a>;
    })}
    </div>;
};
export default Documents;