import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import useToggle from 'hooks/useToggle';
import styles from './Spoiler.module.scss';
const Spoiler: React.FC<HTMLAttributes<HTMLSpanElement>> = ({
  children
}) => {
  const [isSpoilerShown, showSpoiler] = useToggle(false);
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!isSpoilerShown) {
      showSpoiler();
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span className={cx({
      [styles.hide]: !isSpoilerShown
    })} onClick={handleClick} data-sentry-component="Spoiler" data-sentry-source-file="Spoiler.tsx">
      {children}
    </span>
  );
};
export default Spoiler;