import React from 'react';
import Highlight from 'react-highlight';
import render, { domToReact, attributesToProps, HTMLReactParserOptions, DOMNode } from 'html-react-parser';
import Spoiler from 'components/Spoiler';
import Button from 'components/Button';
import { Link } from 'utils/navigation';
const options: HTMLReactParserOptions = {
  replace(DOMNode) {
    if (!('attribs' in DOMNode)) {
      return undefined;
    }
    const {
      attribs,
      children,
      name
    } = DOMNode;
    if (!attribs) {
      return undefined;
    }
    if (attribs['data-muid']) {
      const postId = attribs['data-postid'];
      const link = postId ? '/channel/[id]/[postId]' : '/channel/[id]';
      return <Link
      // @ts-ignore
      href={{
        pathname: link,
        params: {
          id: attribs['data-muid'],
          postId
        }
      }}>
          {domToReact(children as DOMNode[], options)}
        </Link>;
    }
    if (attribs.class === 'spoiler') {
      return <Spoiler>{domToReact(children as DOMNode[], options)}</Spoiler>;
    }
    if (attribs['data-language']) {
      const [child] = children;
      return <Highlight className={`language-${attribs['data-language']}`}>
          {/* @ts-ignore */}
          {child?.data}
        </Highlight>;
    }
    if (name === 'button') {
      return <Button variant="secondary" {...attributesToProps(attribs)}>
          {domToReact(children as DOMNode[], options)}
        </Button>;
    }
    return undefined;
  }
};
function renderer(text: string) {
  return render(text, options);
}
export default renderer;