const convertDuration = (duration: number): string => {
  const minute = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration - minute * 60) % 60);
  const secondsFormatter = new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 2,
  });

  return `${minute}:${secondsFormatter.format(seconds)}`;
};

export default convertDuration;
