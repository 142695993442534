import React, { ComponentProps, useContext } from 'react';
import cx from 'classnames';
import { PostContext } from 'components/PostContentParts/PostContentParts';
import Button from 'components/Button';
import ChannelAvatar from 'components/Avatar/Channel';
import Typography from 'components/Typography';
import { getRoutePath } from 'utils/routing';
import styles from './ChannelInfo.module.scss';
interface ChannelInfoProps {
  avatarSize?: ComponentProps<typeof ChannelAvatar>['size'];
  className?: string;
  isDark?: boolean;
}
const ChannelInfo: React.FC<ChannelInfoProps> = ({
  isDark,
  className,
  avatarSize = '32'
}) => {
  const post = useContext(PostContext);
  if (!post || !post.channel) {
    return null;
  }
  const {
    channel
  } = post;
  return <Button href={getRoutePath.channel({
    channelId: channel.id
  }).root} variant="secondary" className={cx(styles.root, className, {
    [styles.dark]: isDark
  })} data-sentry-element="Button" data-sentry-component="ChannelInfo" data-sentry-source-file="ChannelInfo.tsx">
      <ChannelAvatar size={avatarSize} name={channel.title} src={channel?.profileImage?.url} alt={`${channel.title} avatar`} verified={channel?.isVerified} context="transparent" data-sentry-element="ChannelAvatar" data-sentry-source-file="ChannelInfo.tsx" />
      <Typography variant="h10bk" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="ChannelInfo.tsx">
        {channel.title}
      </Typography>
    </Button>;
};
export default ChannelInfo;