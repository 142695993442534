/* eslint-disable no-bitwise,import/prefer-default-export,no-plusplus */
export function decodeWaveform(data: string, targetBarsCount?: number) {
  const bytes = Array.from(atob(data)).map(x => x.charCodeAt(0) & 0xff);
  let waveform = [];
  const barsCount = Math.floor((bytes.length * 8) / 5);
  for (let i = 0; i < barsCount; i++) {
    const byteIndex = Math.floor((i * 5) / 8);
    const barPadding = (i * 5) % 8;

    const bits =
      bytes[byteIndex] | ((byteIndex + 1 < bytes.length ? bytes[byteIndex + 1] : 0) << 8);
    waveform.push(((bits >>> barPadding) & 0x1f) / 31.0);
  }

  if (targetBarsCount && waveform.length > targetBarsCount) {
    const getEach = Math.floor(waveform.length / targetBarsCount);
    const prevWaveform = [...waveform];
    waveform = [];
    for (let i = 0; i < targetBarsCount; i++) {
      waveform.push(prevWaveform[i * getEach]);
    }
  }

  return waveform;
}
