/* eslint-disable import/prefer-default-export */
export const getRoutePath = {
  home: '/',
  channel: ({ channelId }: { channelId: string }) => ({
    root: `/channel/${channelId}`,
    stats: `/channel/${channelId}/stats`,
    posts: `/channel/${channelId}/posts`,
    ads: `/channel/${channelId}/advertising-effectiveness?groupByChannel=true`,
    audience: `/channel/${channelId}/audience`,
  }),
  post: ({ channelId, postId }: { channelId: string; postId: string | number }) => ({
    root: `/channel/${channelId}/${postId}`,
    views: `/channel/${channelId}/${postId}/stats/views`,
    citation: `/channel/${channelId}/${postId}/stats/citation`,
    reactions: `/channel/${channelId}/${postId}/stats/reactions`,
    subscribers: `/channel/${channelId}/${postId}/stats/subscribers`,
  }),
};
