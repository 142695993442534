import React, { HTMLAttributes, useCallback, useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import cx from 'classnames';
import { PostType } from 'business/channel/repository/types.d';
import useToggle from 'hooks/useToggle';
import getStaticStormUrl from 'utils/staticStorm';
import { ReactComponent as PlayIcon } from 'public/assets/icons/play.svg';
import { ReactComponent as PauseIcon } from 'public/assets/icons/pause.svg';
import { PostContext } from '../PostContentParts';
import styles from './VideoCircle.module.scss';
const VideoCircle: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const post = useContext(PostContext);
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [isPlaying, play, pause] = useToggle(false);
  const video = post?.media.find(media => media.mediaType === PostType.VideoNote);
  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, pause, play]);
  const handleEnded = useCallback(() => {
    player?.seekTo(0);
    pause();
  }, [pause, player]);
  if (!video || !video.url) return null;
  return <div className={cx(styles.root, className, {
    [styles.playing]: isPlaying
  })} {...props} data-sentry-component="VideoCircle" data-sentry-source-file="VideoCircle.tsx">
      <ReactPlayer ref={el => setPlayer(el)} url={getStaticStormUrl(video.url)} className={styles.player} playing={isPlaying} width="100%" height="100%" onEnded={handleEnded} controls={false} data-sentry-element="ReactPlayer" data-sentry-source-file="VideoCircle.tsx" />
      <button type="button" onClick={handlePlayPause} className={styles.overlay}>
        {isPlaying ? <PauseIcon className={styles.icon} onClick={pause} /> : <PlayIcon className={styles.icon} onClick={play} />}
      </button>
    </div>;
};
export default VideoCircle;