import React, { useMemo } from 'react';
import Slider, { SliderValueLabelProps } from '@mui/material/Slider/Slider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import cx from 'classnames';
import useToggle from 'hooks/useToggle';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import convertDuration from 'utils/convertDuration';
import { ReactComponent as PlayIcon } from 'public/assets/icons/play.svg';
import { ReactComponent as PauseIcon } from 'public/assets/icons/pause.svg';
import { ReactComponent as SoundMuteIcon } from 'public/assets/icons/sound-mute.svg';
import { ReactComponent as SoundMediumIcon } from 'public/assets/icons/sound-med.svg';
import { ReactComponent as SoundHighIcon } from 'public/assets/icons/sound-high.svg';
import { ReactComponent as CloseIcon } from 'public/assets/icons/close.svg';
import styles from './VideoControls.module.scss';
interface VideoControlsProps {
  playerRef: React.RefObject<ReactPlayer>;
  state: ReactPlayerProps;
  dispatch: React.Dispatch<ReactPlayerProps>;
  onReset?: () => void;
  classes?: {
    reset?: string;
    controls?: string;
  };
}
const getVolumeIcon = (volume?: number) => {
  if (volume === 0) return <SoundMuteIcon />;
  if (volume && volume >= 0.5) return <SoundHighIcon />;
  return <SoundMediumIcon data-sentry-element="SoundMediumIcon" data-sentry-component="getVolumeIcon" data-sentry-source-file="VideoControls.tsx" />;
};
const SeekSliderLabelComponent = (duration: number): React.FC<SliderValueLabelProps> => {
  const convertedDuration = convertDuration(duration);
  return function SeekSliderLabelComponentInner({
    children,
    value
  }) {
    return <Tooltip title={`${convertDuration(value)} / ${convertedDuration}`} enterTouchDelay={0} placement="top">
        {children}
      </Tooltip>;
  };
};
const VideoControls: React.FC<VideoControlsProps> = ({
  classes = {},
  state,
  dispatch,
  playerRef,
  onReset
}) => {
  const [isVolumeShown, showVolume, hideVolume] = useToggle(false);
  const handlePlayPause = () => {
    dispatch({
      type: 'TOGGLE_PLAY'
    });
  };
  const handleSound = (_event: Event, newValue: number | number[]) => {
    dispatch({
      type: 'VOLUME',
      payload: newValue
    });
  };
  const handleSeek = (_event: Event, newValue: number | number[]) => {
    playerRef.current?.seekTo(newValue as number, 'seconds');
  };
  const handleResetPlayer = () => {
    dispatch({
      type: 'RESET'
    });
    playerRef.current?.showPreview();
    onReset?.();
  };
  const handleVolumeSwitch = () => {
    // eslint-disable-next-line no-unused-expressions
    isVolumeShown ? hideVolume() : showVolume();
  };
  const sliderSlots = useMemo(() => ({
    valueLabel: SeekSliderLabelComponent(state.duration)
  }), [state.duration]);
  return <>
      <Button size="medium" variant="light" rounded onClick={handleResetPlayer} className={classes?.reset} data-sentry-element="Button" data-sentry-source-file="VideoControls.tsx">
        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="VideoControls.tsx" />
      </Button>
      <div className={cx(styles.root, classes?.controls)}>
        <Button size="medium" variant="light" rounded onClick={handlePlayPause} data-sentry-element="Button" data-sentry-source-file="VideoControls.tsx">
          {state.playing ? <PauseIcon /> : <PlayIcon />}
        </Button>
        <div className={styles.seekSlider}>
          <Slider aria-label="Time" valueLabelDisplay="auto" slots={sliderSlots} classes={{
          root: styles.slider,
          track: styles.track,
          thumb: styles.thumb,
          rail: styles.rail
        }} min={0} max={state.duration} step={0.01} value={state.progress.playedSeconds} onChange={handleSeek} data-sentry-element="Slider" data-sentry-source-file="VideoControls.tsx" />
        </div>
        <ClickAwayListener onClickAway={hideVolume} data-sentry-element="ClickAwayListener" data-sentry-source-file="VideoControls.tsx">
          <div className={styles.volume}>
            <Button size="medium" variant="light" rounded onClick={handleVolumeSwitch} data-sentry-element="Button" data-sentry-source-file="VideoControls.tsx">
              {getVolumeIcon(state.volume)}
            </Button>
            <div className={cx(styles.volumeSlider, {
            [styles.shown]: isVolumeShown
          })}>
              <Slider classes={{
              root: styles.slider,
              track: styles.track,
              thumb: styles.thumb,
              rail: styles.rail
            }} aria-label="Volume" orientation="vertical" max={1} step={0.01} value={state.volume} onChange={handleSound} data-sentry-element="Slider" data-sentry-source-file="VideoControls.tsx" />
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </>;
};
export default VideoControls;